<template>
  <div class="content-logo">
<!--    <img src="/images/emoji-half-top.png" />-->
    <div class="content-titles">
      <div v-for="title in titles"
           class="title"
           :key="title">
        <span v-for="(symbol, idx) in title" :key="idx">
          <span>{{symbol}}</span>
        </span>
      </div>
    </div>
<!--    <img src="/images/emoji-half-bottom.png" />-->
  </div>
</template>

<script>
  export default {
    name: 'ContentLogo',
    data() {
      return {
        titles: ['STOP', 'THE', "WAR", 'IN', 'UKRAINE'],
      };
    },
  }
</script>

<style lang="scss" scoped>
  .content-titles{
    margin-bottom: 15px;
  }

  .content-logo {
    text-align: center;

    img{
      height: 100px;
      width: 215px;
    }

    .title{
      font-size: 80px;
      font-weight: 900;
      cursor: pointer;
      line-height: 70px;

      span:hover{
        background: linear-gradient(to bottom, #3737f2 51%, #ffe82f 51% );
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    }
  }


  @media (max-width: $table-screen) {
    .content-logo {
      margin-bottom: 50px;
    }
  }
</style>
