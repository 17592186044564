<template>
  <section class="main-page-content default-padding">
    <content-logo />
  </section>
</template>

<script>
  import ContentLogo from '../components/ContentLogo';
  export default {
    name: 'Main',
    components: {
      ContentLogo,
    },
  }
</script>

<style lang="scss" scoped>
  .main-page-content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: calc(100vh - 230px);

    @media (max-width: $mobile-screen) {
      min-height: calc(100vh - 150px);
    }
  }

</style>
